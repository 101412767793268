import { useEffect, useMemo, useState } from 'react';
import { localStorage } from '~/modules/root/util/browser-storage';
import type { SaveToDesignBoardProps } from '~/modules/design-board/component/SaveToDesignBoard';
import type {
    UserFavouritedItem,
    FavouriteItem,
} from '../type/design-board-types';
import { isFavouritedItem } from '../util/design-board-utils';
import { USER_FAVOURITED_ITEM_STORAGE_KEY } from '../config/design-board-config';

export const useSaveToDesignBoard = ({
    itemId,
    itemType,
    libraryItemId,
}: {
    itemId: SaveToDesignBoardProps['itemId'];
    itemType: SaveToDesignBoardProps['itemType'];
    libraryItemId?: SaveToDesignBoardProps['libraryItemId'];
}) => {
    const [popupTarget, setPopupTarget] = useState<HTMLElement>();
    const [clickToken, setClickToken] = useState<number>();
    const [favouritedItems, setFavouritedItems] = useState<
        UserFavouritedItem[]
    >([]);
    const isFavourited = useMemo(() => {
        return isFavouritedItem(itemId, itemType, favouritedItems);
    }, [itemId, itemType, favouritedItems]);

    const onFavouriteClick = (e: React.SyntheticEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setPopupTarget(e.currentTarget as HTMLElement);
        setClickToken(Math.random());
    };
    const onFavouriteSave = (favouritedItem: UserFavouritedItem) => {
        setFavouritedItems([...favouritedItems, favouritedItem]);
    };
    const favourited = (itemId?: number) => {
        if (!itemId) {
            return false;
        }
        return isFavouritedItem(itemId, itemType, favouritedItems);
    };

    useEffect(() => {
        const handleLocalStorageChange = () => {
            const item = JSON.parse(
                localStorage?.getItem(USER_FAVOURITED_ITEM_STORAGE_KEY) || '[]'
            );

            setFavouritedItems((currentItem) => {
                const currentIsEmpty = currentItem.length === 0;
                const newIsEmpty = item.length === 0;
                const shouldUpdate = !(currentIsEmpty && newIsEmpty);
                // React runs a refrential equality check which fails on empty arrays
                if (item && shouldUpdate) {
                    return item;
                }
                return currentItem;
            });
        };

        handleLocalStorageChange();
        window.addEventListener('storage', handleLocalStorageChange);

        return () => {
            window.removeEventListener('storage', handleLocalStorageChange);
        };
    }, []);

    const favouriteItem: FavouriteItem = {
        itemId,
        itemType,
        libraryItemId,
        target: popupTarget,
        clickToken,
        isFavourited,
        onSave: onFavouriteSave,
        onClick: onFavouriteClick,
        favourited,
    };

    return {
        favouriteItem,
        favouritedItems,
    };
};
